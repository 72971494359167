import React, { FC, ReactElement } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { ContactForm, SliceRenderer } from '../containers';
import { transformToMetaData } from '../models/Meta';

const TryParley: FC = (): ReactElement => {
    const data = useStaticQuery(graphql`
        query ProbeerParleyComponents {
            slices: allPrismicProbeerParley {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
        }
    `);

    const apiData = data.slices.edges[0].node.dataRaw;

    const slices = apiData.body;
    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    return (
        <ConnectedPage metaData={metaData} className="try-parley-page">
            <SliceRenderer
                slices={slices}
                className="try-parley-page__slice-renderer"
            />

            <ContactForm />
        </ConnectedPage>
    );
};

export default TryParley;
